/*******************   */
/* foo */
html {
  font-size: 62.5%;
}

body {
  overflow: hidden;
  background-color: white;
  color: #333;
  font-weight: bold;
  line-height: 1;
  font-family: "M PLUS 2", "Kiwi Maru", sans-serif;
  letter-spacing: 0;
}

a {
  display: block;
  width: 100%;
  height: 100%;
  color: #333;
  text-decoration: none;
}

main {
  position: fixed;
  top: 0;
  left: 0;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;
  padding: 64px 15px 94px;
  overflow: hidden;
}

#root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
          flex: 1;
          flex-direction: column;
  height: 100%;
}

/* Box sizing rules */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizespeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
}

img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
  height: auto;
  vertical-align: bottom;
  pointer-events: none;
          user-select: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  outline: none;
  background: none;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    -webkit-transition-duration: 0.01ms !important;
    -webkit-animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-duration: 0.01ms !important;
            animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
            transition-duration: 0.01ms !important;
  }
}
